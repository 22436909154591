export const stepsNames = {
    DOCUMENT_SUBMISSION: "FILING",
    E_SIGNATURE: "POAAPPLICATION",
    PUBLICATION: "PUBLICATION",
    REGISTRATION: "REGISTRATION",
    TMCR_DOWNLOAD: "TMCRDOWNLOAD"
};

export const caseStatus = {
    FILE_DOWNLOADED: "FileDownloaded",
    OPEN: "Open",
    CLOSED: "Closed"
};

export const responseStatus = {
    COMPLETED: "Completed",
    REJECTED: "Rejected",
    AWAITING: "Awaiting",
    PENDING: "Pending",
    DISPATCHED: "Dispatched"
};

export const stepTitle = {
    DOCUMENT_SUBMISSION: "Document Submission",
    E_SIGNATURE: "E Signature",
    PUBLICATION: "Publication",
    REGISTRATION: "Registration",
    TMCR_DOWNLOAD: "TMCR"
};

export const accordionStatus = {
    OPEN: "Open",
    COMPLETED: "Completed",
    REJECTED: "Rejected"
};

export const buttonTitle = {
    SUBMIT: "",
    SEND: "Send",
    MOVE_TO_NEXT_STEP: "Move To Next Step",
    REJECT: "Reject",
    DISPATCH: "Mark as Dispatched"
};

export const documentTypePayload = {
    TRADEMARK_REGISTRATION: "TrademarkRegistration",
    TRADEMARK_PUBLICATION: "TrademarkPublicationForm",
    TRADEMARK_POWER_ATTORNEY: "PowerOfAttorney",
    TRADEMARK_APPLICATION: "Application"
};

export const responseMessages = {
    SUCCESS_DOCUMENT_SUBMISSION: "",
    SUCCESS_E_SIGNATURE: "E Signature Step has been submitted successfully.",
    SUCCESS_PUBLICATION: "Publication Step has been updated successfully.",
    SUCCESS_REGISTRATION: "Registration Step has been submitted successfully.",
    SUCCESS_TMCR: "TMCRDownload Step has been submitted successfully.",
    FILE_TYPE_ERROR: "File type is not supported!"
};

export const fieldRequirementMessage = {
    DATE_FIELD: "Please enter the date",
    STEP_FEE: "Step Fee is required.",
    STEP_FEE_MIN_LIMIT: "Step Fee shouldn't be less than $1",
    STEP_FEE_MAX_LIMIT: "Step Fee shouldn't be greater than $1000",
    UPLOAD_FILE: "Kindly upload the file"
};

export const dateFormat = {
    expiryDate: "YYYY-MM-DDTHH:mm:ss.SSSSZ",
    yearMonthDate: "YYYY-MM-DD"
};

export const shippingFee = {
    DATE_FIELD: "Please enter the date",
    STEP_FEE: "Shipping Fee is required.",
    STEP_FEE_MIN_LIMIT: "Shipping Fee shouldn't be less than $1",
    STEP_FEE_MAX_LIMIT: "Shipping Fee shouldn't be greater than $1000"
};
